<template>
  <div class="gateway-header" :style="{ background: bgAttr }">
    <!-- <div class="gateway-header" :style="{ bgAttr }"> -->
    <div
      class="logo"
      @click="$router.push('/homepage')"
      style="cursor: pointer"
    >
      <img src="../assets/img/gateway/homepage/logo.png" alt="" />
      <h1>产业园综合信息门户</h1>
    </div>
    <nav class="header-nav">
      <el-menu
        id="elMenu"
        :default-active="activeIndex"
        mode="horizontal"
        @select="handleSelect"
        background-color="#3659F1"
        active-text-color="#fff"
        text-color="#fff"
      >
        <span
          v-for="(ele, i) in nav"
          :key="i"
          :class="{
            'menu-active': $route.fullPath === ele.link,
          }"
          @mouseenter="handleMoveEnter(ele, i)"
          @mouseleave="menuActive = ''"
        >
          <el-menu-item v-if="!ele.children" :index="ele.link">
            {{ ele.txt }}
          </el-menu-item>
          <el-submenu v-else :index="i + '-' + i + 1">
            <template slot="title">{{ ele.txt }}</template>
            <span v-for="(cli, c) in ele.children" :key="c">
              <el-menu-item v-if="!cli.children" :index="cli.link">
                {{ cli.txt }}
              </el-menu-item>
              <el-submenu v-else :index="i + '-' + c + 1">
                <template slot="title">{{ cli.txt }}</template>
                <el-menu-item
                  v-for="(gra, g) in cli.children"
                  :key="g"
                  :index="gra.link"
                >
                  {{ gra.txt }}
                </el-menu-item>
              </el-submenu>
            </span>
          </el-submenu>
        </span>
      </el-menu>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'gateway-header',
  data() {
    return {
      menuActive: 0,
      mouseenterLink: '',
      nav: [
        {
          txt: '首页',
          link: '/homepage',
        },
        {
          txt: '湛渔品牌',
          link: '/zyBrand',
          children: [
            {
              parentPath: 'douyin',
              txt: '湛鱼品牌短视频',
              link: 'https://www.douyin.com/user/MS4wLjABAAAAg1dBhWIYhGReaeHWiwR6NR6wqQebbNHnz6fFYFnC0Wc',
            }
          ]
        },
        {
          txt: '漫游产业园',
          link: '/industrialPark',
        },
        {
          txt: '产业园服务',
          link: '/parkService',
          children: [
            {
              parentPath: '/expertServices',
              txt: '专家服务',
              link: '/expertServices',
            },
            {
              parentPath: '/parkService',
              txt: '知识库服务',
              link: '/parkService',
            },
            {
              parentPath: '/parkService',
              txt: '项目信息中心',
              link: '/projectCenter',
            },
            {
              parentPath: '/zhanyu/overview',
              txt: '深海养殖一张图',
              link: '/zhanyu/overview',
            },
            {
              parentPath: 'ufish-admin',
              txt: '养殖管理公共服务',
              link: '//ufish-admin.uyu1027.com/#/login?from=zhanyu',
            },
          ],
        },
        {
          txt: '新闻中心',
          link: '/newsCenter',
        },
      ],
      activeIndex: '1',
    }
  },
  props: {
    bgAttr: String,
  },
  computed: {},
  methods: {
    addEvent(){
      const elMenu = document.getElementById('elMenu')
      const clidrens = elMenu.children
      for(var e = 0; e<clidrens.length; e++){
        if(clidrens[e].innerText==='湛渔品牌'){
          let url = this.nav.find(n => n.txt===clidrens[e].innerText)
          clidrens[e].onclick = function(){
            window.open(window.location.origin + url.link,'_self')
          }
          break
        }
      }
      console.dir(elMenu);
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-27 15:45:46
     * @Descripttion: 菜单移入
     * @params {*}
     * @param {*} data
     * @param {*} index
     */
    handleMoveEnter(data, index) {
      this.menuActive = index
    },
    handleSelect(key, keyPath) {
      if (key === '/zhanyu/overview') {
        let routeData = this.$router.resolve({ path: key })
        window.open(routeData.location.path, '_blank')
      } else if (
        key === '//ufish-admin.uyu1027.com/#/login?from=zhanyu'
      ) {
        this.addLog('https' + key, 'PUBLIC_SERVICES')
        window.open(key, '_blank')
      } else if (key === 'https://www.douyin.com/user/MS4wLjABAAAAg1dBhWIYhGReaeHWiwR6NR6wqQebbNHnz6fFYFnC0Wc'){
        // todo
        this.addLog(key, 'DOU_YIN')
        window.open(key, '_blank')
      } else {
        !this.$route.fullPath.includes(key) && this.$router.push(key)
      }
    },
    addLog(uri, type) {
          console.log( uri, type)
      this.$axios({
          url: '/zhanjiang-admin/systemLog/logSave',
          method: 'post',
          data: {
            requestUri: uri,
            moduleType: type,
            // optTime: '2023-6-1'
            // 	排序列表（key:需要排序的字段名 value:[asc(升序),desc(倒序)]）
          },
        }).then((r) => {
          console.log('', r, uri, type)
        })
    }
  },
  beforeMount() {
    // 模板编译/挂载之前
    document.title = '产业园综合信息门户'
  },
  mounted() {
    setTimeout(()=>{
      this.addEvent()
    },1000)
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  // watch: {
  // keyName: {
  // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
  // deep: true,
  //handler (curVal, oldVal) {
  // console.log(curVal, oldVal)
  //}
  // }
  // },
}
</script>
<style lang="less" scoped>
.gateway-header {
  position: absolute;
  z-index: 11;
  top: 0;

// overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 0 60px;
  background: rgba(54, 89, 241, 0.6);

  .logo {
    display: inline-flex;
    height: 100%;

    align-items: center;
    h1 {
      margin-left: 7px;
      color: #ffffff;
      font-family: 'Microsoft YaHei';
      font-size: 18px;
      font-weight: 400;
    }
    img {
      display: block;
      overflow: hidden;
      width: 27px;
      height: 27px;
      border-radius: 50%;
    }
  }
  nav {
    float: right;
    height: 100%;
    ul {
      display: flex;
      height: 100%;
      cursor: pointer;

      align-items: center;
      justify-content: center;
    }
    li {
      margin-left: 64px;
      color: #e6e6e6;
      font-family: 'Microsoft YaHei';
      font-size: 14px;
      font-weight: 400;
    }
    /deep/ .el-menu {
      background: transparent !important;
      .el-menu-item,
      .el-submenu__title {
        background: transparent !important;
      }
      .el-submenu__title {
        color: white;
        i {
          position: relative;
          top: 0;
          left: 5px;
          color: white;
        }
      }
    }
  }
}




</style>
<style lang="less">
.el-menu--horizontal[x-placement='bottom-start'] {
  top: 45px !important;
  width: 150px !important;
  // overflow: hidden;
  /deep/ .el-menu {
    width: 130px !important;
  }
  .el-submenu__title,
  .el-menu-item {
    &:hover {
      background-color: #2438fc !important;
    }
  }
  .el-submenu__title i {
    color: white;
  }
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup,
.el-submenu .el-menu-item {
  min-width: 130px;
}
.el-submenu .el-menu-item {
  &:hover {
    background-color: #2438fc !important;
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 1px transparent;
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  padding-top: 12px;
  border-radius: 0;
}
.el-menu--horizontal .el-menu .el-menu-item {
  height: 44px;
  padding: 2px 0 0 20px;
}
.menu-active {
  li {
    position: relative;
    &::before {
      position: absolute;
      z-index: 100;
      bottom: 0;
      left: 25%;
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
      width: 100%;
      height: 5px;
      border-radius: 10px;
      background-image: linear-gradient(to right, #fe69fb, #089ddd, #6be0bd);
      content: '';
      -webkit-transform: scale(0.5, 0.5);
          -ms-transform: scale(0.5, 0.5);
           -o-transform: scale(0.5, 0.5);
              transform: scale(0.5, 0.5);
      -webkit-transform-origin: 0 0;
         -moz-transform-origin: 0 0;
          -ms-transform-origin: 0 0;
           -o-transform-origin: 0 0;
              transform-origin: 0 0;
    }
  }
}




</style>
