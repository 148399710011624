<template>
  <footer class="clearfix">
    <div class="footer-content">
      <div class="footer-left">
        <span>主办单位：湛江市水产流通与加工协会</span>
        <span>联系电话：0759-2080658</span>
        <span>电子信箱：zjaap@vip.163.com</span>
        <span
          >通信地址：湛江经济技术开发区人民大道北3号盛和园23号楼1层04号商铺</span
        >
        <!-- <p style="color: gray">
          技术支持：<a style="color: gray" href="https://www.uyu1027.com" target="_blank"
            >优鱼（广州）技术有限公司</a
          >
        </p> -->
        <p style="margin-top: 0">
          备案号：<a href="https://beian.miit.gov.cn/">粤ICP备2021168683号-2</a>
        </p>
      </div>
      <div class="footer-right">
        <div>
          <span
            >移动端二维码<img src="./../assets/icon/qrcode-wechat.png" alt="" />
          </span>

          <span
            >湛渔商城APP<img src="./../assets/icon/app.png" alt=""
          /></span>
          <span
            >抖音二维码<img src="./../assets/icon/qrcode-titok.png" alt=""
          /></span>
        </div>
        <!-- <span>媒体合作：Media@cl.com</span> -->
      </div>
    </div>
    <!-- <p style="position: absolute;bottom: 10px;text-align:center;width: 100%">备案号：<a href="https://beian.miit.gov.cn/">粤ICP备2021168683号-2</a></p> -->
  </footer>
</template>
<script>
export default {
  name: 'gateway-footer',
  data() {
    return {}
  },
  computed: {},
  methods: {},
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
footer {
  padding: 118px 0;
  background: #031236;
  color: #ffffff;
  position: relative;
  a{
    // text-decoration: underline;
  }
  .footer-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;

    justify-content: space-between;
    .footer-left,
    .footer-right {
      display: flex;
      flex-direction: column;
      height: 152px;
      font-family: 'Microsoft YaHei';
      font-size: 14px;
      font-weight: 400;

      justify-content: space-between;
      div {
        display: flex;

        // overflow: hidden;
        span {
          margin-left: 18px;
          text-align: center;
        }
      }
      img {
        display: block;
        width: 140px;
        height: 140px;
        margin-top: 10px;
      }
      p {
        margin-top: 34px;
      }
    }
  }
}

</style>
